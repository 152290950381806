import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Step = ({ step, label, icon }) => (
  <div className={styles['step']}>
    <span className={styles['step__number']}>{step}</span>
    <span className={styles['step__label']}>{label}</span>
    <span className={styles['step__icon']}>
      <img src={icon.url} alt={icon.alt} title={icon.alt} />
    </span>
  </div>
);

const BBStepperWithIcons = ({ steps }) => {
  return (
    <div className={`bb ${styles['bb-stepper-with-icons']}`}>
      <div className={styles['bb-stepper-with-icons__container']}>
        <div className="container">
          <div className={styles['bb-stepper-with-icons__steps']}>
            {steps.map((step, index) => (
              <React.Fragment key={step.step}>
                <Step {...step} />
                {index !== steps.length - 1 && (
                  <div className={styles['bb-stepper-with-icons__arrow']}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                    >
                      <path
                        d="M30,11.5l-1.41,1.5,5.58,5.94H2v2.12H34.17l-5.58,5.94,1.41,1.5,8-8.5-8-8.5Z"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
};

BBStepperWithIcons.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      label: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default BBStepperWithIcons;
